/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */
@import "../assets/styles/mixins";

html,
body {
  height: 100%;
}

body {
  font-family: var(--font-family);
}

p {
  margin: 0;
}

// Global customize components
body {
  .p-contextmenu {
    box-shadow: 2px 2px 8px 4px var(--bs-gray);

    .p-contextmenu-root-list {
      margin: 0;
      padding: 0;
    }

    .p-menuitem > .p-menuitem-content .p-menuitem-link {
      font-size: 12px;
      text-decoration: none;
    }
  }

  .p-dialog .p-dialog-header {
    background-color: var(--app-bg-primary);
  }

  .p-dropdown-panel .p-dropdown-items {
    margin: 0;
    padding: 0;
  }

  .p-disabled,
  .p-component:disabled {
    opacity: 0.6;
  }

  .p-card {
    box-shadow:
      0 2px 1px -1px var(--app-border),
      0 1px 1px 0 var(--app-border),
      0 1px 3px 0 var(--app-border);
  }
}

textarea {
  resize: none;
}

@include thin-scrollbar;
