@mixin thin-scrollbar($lane-width: 6px) {
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 $lane-width rgba(0, 0, 0, 0.3);
    border-radius: 9999px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar {
    width: $lane-width;
    height: $lane-width;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 9999px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
  }
}
