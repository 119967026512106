.icon {
  background-size: contain;
  background-position: center;
  width: 28px !important;
  height: 28px !important;
  display: inline-block;
  background-repeat: no-repeat;
}
.icon-share {
  background-image: url("/assets/images/icons/share.png");
}
.icon-artificial-intelligence {
  background-image: url("/assets/images/icons/artificial-intelligence.png");
}
.icon-Autoplay-Backward {
  background-image: url("/assets/images/icons/Autoplay-Backward.png");
}
.icon-Autoplay-FastBackward {
  background-image: url("/assets/images/icons/Autoplay-FastBackward.png");
}
.icon-Autoplay-FastForward {
  background-image: url("/assets/images/icons/Autoplay-FastForward.png");
}
.icon-Autoplay-Forward {
  background-image: url("/assets/images/icons/Autoplay-Forward.png");
}
.icon-Autoplay-Loop {
  background-image: url("/assets/images/icons/Autoplay-Loop.png");
}
.icon-Autoplay-play {
  background-image: url("/assets/images/icons/Autoplay-play.png");
}
.icon-Autoplay-Repeat {
  background-image: url("/assets/images/icons/Autoplay-Repeat.png");
}
.icon-Autoplay-stop {
  background-image: url("/assets/images/icons/Autoplay-stop.png");
}
.icon-AutoScroll {
  background-image: url("/assets/images/icons/AutoScroll.png");
}
.icon-Cine {
  background-image: url("/assets/images/icons/Cine.png");
}
.icon-CobbAngle {
  background-image: url("/assets/images/icons/CobbAngle.png");
}
.icon-CTR {
  background-image: url("/assets/images/icons/CTR.png");
}
.icon-cursorPan {
  background-image: url("/assets/images/icons/cursorPan.png");
}
.icon-cursorRotate {
  background-image: url("/assets/images/icons/cursorRotate.png");
}
.icon-CursorScroll {
  background-image: url("/assets/images/icons/CursorScroll.png");
}
.icon-Cursor-Select-icon {
  background-image: url("/assets/images/icons/Cursor-Select-icon.png");
}
.icon-cursorWWWL {
  background-image: url("/assets/images/icons/cursorWWWL.png");
}
.icon-cursorZoom {
  background-image: url("/assets/images/icons/cursorZoom.png");
}
.icon-Default {
  background-image: url("/assets/images/icons/Default.png");
}
.icon-Display {
  background-image: url("/assets/images/icons/Display.png");
}
.icon-DisplayClosePanel {
  background-image: url("/assets/images/icons/DisplayClosePanel.png");
}
.icon-DisplayRefer {
  background-image: url("/assets/images/icons/DisplayRefer.png");
}
.icon-Display-ReferenceLine {
  background-image: url("/assets/images/icons/Display-ReferenceLine.png");
}
.icon-Display-CrossHair {
  background-image: url("/assets/images/icons/crosshair.png");
}
.icon-DrawingColor {
  background-image: url("/assets/images/icons/DrawingColor.png");
}
.icon-DrawingLineThickness {
  background-image: url("/assets/images/icons/DrawingLineThickness.png");
}
.icon-DrawingOption {
  background-image: url("/assets/images/icons/DrawingOption.png");
}
.icon-DrawingTextSize {
  background-image: url("/assets/images/icons/DrawingTextSize.png");
}
.icon-dwvicon_Annotation {
  background-image: url("/assets/images/icons/dwvicon_Annotation.png");
}
.icon-dwvicon_ChangePanel {
  background-image: url("/assets/images/icons/dwvicon_ChangePanel.png");
}
.icon-dwvicon_ChangeTile {
  background-image: url("/assets/images/icons/dwvicon_ChangeTile.png");
}
.icon-dwvicon_ChangeViewScreen {
  background-image: url("/assets/images/icons/dwvicon_ChangeViewScreen.png");
}
.icon-dwvicon_dropbox {
  background-image: url("/assets/images/icons/dwvicon_dropbox.png");
}
.icon-dwvicon_FullScreen {
  background-image: url("/assets/images/icons/dwvicon_FullScreen.png");
}
.icon-dwvicon_GoogleDrive {
  background-image: url("/assets/images/icons/dwvicon_GoogleDrive.png");
}
.icon-dwvicon_MeasureLength {
  background-image: url("/assets/images/icons/dwvicon_MeasureLength.png");
}
.icon-dwvicon_More {
  background-image: url("/assets/images/icons/dwvicon_More.png");
}
.icon-dwvicon_OneDrive {
  background-image: url("/assets/images/icons/dwvicon_OneDrive.png");
}
.icon-dwvicon_Overlay {
  background-image: url("/assets/images/icons/dwvicon_Overlay.png");
}
.icon-dwvicon_OverlayInfo {
  background-image: url("/assets/images/icons/dwvicon_OverlayInfo.png");
}
.icon-dwvicon_Panning {
  background-image: url("/assets/images/icons/dwvicon_Panning.png");
}
.icon-dwvicon_ScopeAll {
  background-image: url("/assets/images/icons/dwvicon_ScopeAll.png");
}
.icon-dwvicon_ScopeNone {
  background-image: url("/assets/images/icons/dwvicon_ScopeNone.png");
}
.icon-dwvicon_ScopePanel {
  background-image: url("/assets/images/icons/dwvicon_ScopePanel.png");
}
.icon-dwvicon_ScopePanels {
  background-image: url("/assets/images/icons/dwvicon_ScopePanels.png");
}
.icon-dwvicon_ScopeStudy {
  background-image: url("/assets/images/icons/dwvicon_ScopeStudy.png");
}
.icon-dwvicon_thumbnail {
  background-image: url("/assets/images/icons/dwvicon_thumbnail.png");
}
.icon-dwvicon_WindowLevel {
  background-image: url("/assets/images/icons/dwvicon_WindowLevel.png");
}
.icon-dwvicon_Zoom {
  background-image: url("/assets/images/icons/dwvicon_Zoom.png");
}
.icon-ExportImage {
  background-image: url("/assets/images/icons/ExportImage.png");
}
.icon-Filter {
  background-image: url("/assets/images/icons/Filter.png");
}
.icon-FitAll {
  background-image: url("/assets/images/icons/FitAll.png");
}
.icon-FitHeight {
  background-image: url("/assets/images/icons/FitHeight.png");
}
.icon-FitWidth {
  background-image: url("/assets/images/icons/FitWidth.png");
}
.icon-FlipHoriz {
  background-image: url("/assets/images/icons/FlipHoriz.png");
}
.icon-FlipVerti {
  background-image: url("/assets/images/icons/FlipVerti.png");
}
.icon-icons8-Cursor-50 {
  background-image: url("/assets/images/icons/icons8-Cursor-50.png");
}
.icon-icons8-Delete-50 {
  background-image: url("/assets/images/icons/icons8-Delete-50.png");
}
.icon-icons8-Full-Screen-Filled-50 {
  background-image: url("/assets/images/icons/icons8-Full-Screen-Filled-50.png");
}
.icon-icons8-Hand-30 {
  background-image: url("/assets/images/icons/icons8-Hand-30.png");
}
.icon-Intensity {
  background-image: url("/assets/images/icons/Intensity.png");
}
.icon-invert {
  background-image: url("/assets/images/icons/invert.png");
}
.icon-LUT {
  background-image: url("/assets/images/icons/LUT.png");
}
.icon-mask {
  background-image: url("/assets/images/icons/mask.png");
}
.icon-More {
  background-image: url("/assets/images/icons/More.png");
}
.icon-NextPanel {
  background-image: url("/assets/images/icons/NextPanel.png");
}
.icon-NextSeries {
  background-image: url("/assets/images/icons/NextSeries.png");
}
.icon-NorbergAngle {
  background-image: url("/assets/images/icons/NorbergAngle.png");
}
.icon-OpenFiles {
  background-image: url("/assets/images/icons/OpenFiles.png");
}
.icon-OpenFiles1 {
  background-image: url("/assets/images/icons/OpenFiles1.png");
}
.icon-Page-Next {
  background-image: url("/assets/images/icons/Page-Next.png");
}
.icon-Page-Previous {
  background-image: url("/assets/images/icons/Page-Previous.png");
}
.icon-Pan-bottom {
  background-image: url("/assets/images/icons/Pan-bottom.png");
}
.icon-Pan-center {
  background-image: url("/assets/images/icons/Pan-center.png");
}
.icon-Panel2x1 {
  background-image: url("/assets/images/icons/Panel2x1.png");
}
.icon-Panel2x3 {
  background-image: url("/assets/images/icons/Panel2x3.png");
}
.icon-Pan-horizontal {
  background-image: url("/assets/images/icons/Pan-horizontal.png");
}
.icon-Pan-left {
  background-image: url("/assets/images/icons/Pan-left.png");
}
.icon-Pan-right {
  background-image: url("/assets/images/icons/Pan-right.png");
}
.icon-Pan-top {
  background-image: url("/assets/images/icons/Pan-top.png");
}
.icon-Pan-vertical {
  background-image: url("/assets/images/icons/Pan-vertical.png");
}
.icon-Patient {
  background-image: url("/assets/images/icons/Patient.png");
}
.icon-PatientLeft {
  background-image: url("/assets/images/icons/PatientLeft.png");
}
.icon-PatientTop {
  background-image: url("/assets/images/icons/PatientTop.png");
}
.icon-Polyline {
  background-image: url("/assets/images/icons/Polyline.png");
}
.icon-Presets {
  background-image: url("/assets/images/icons/Presets.png");
}
.icon-PreviousPanel {
  background-image: url("/assets/images/icons/PreviousPanel.png");
}
.icon-PreviousSeries {
  background-image: url("/assets/images/icons/PreviousSeries.png");
}
.icon-Report {
  background-image: url("/assets/images/icons/Report.png");
}
.icon-Report-ChangeType1 {
  background-image: url("/assets/images/icons/Report-ChangeType1.png");
}
.icon-Report-ChangeType2 {
  background-image: url("/assets/images/icons/Report-ChangeType2.png");
}
.icon-Report-ImageEntire {
  background-image: url("/assets/images/icons/Report-ImageEntire.png");
}
.icon-Report-ImageRegion {
  background-image: url("/assets/images/icons/Report-ImageRegion.png");
}
.icon-Reset {
  background-image: url("/assets/images/icons/Reset.png");
}
.icon-ResetWWL {
  background-image: url("/assets/images/icons/ResetWWL.png");
}
.icon-Rotate {
  background-image: url("/assets/images/icons/Rotate.png");
}
.icon-Rotate-Manual {
  background-image: url("/assets/images/icons/Rotate-Manual.png");
}
.icon-Rotate-Reset {
  background-image: url("/assets/images/icons/Rotate-Reset.png");
}
.icon-RotateRight {
  background-image: url("/assets/images/icons/RotateRight.png");
}
.icon-ScreenOne {
  background-image: url("/assets/images/icons/ScreenOne.png");
}
.icon-ScreenThree {
  background-image: url("/assets/images/icons/ScreenThree.png");
}
.icon-ScreenTwo {
  background-image: url("/assets/images/icons/ScreenTwo.png");
}
.icon-Scroll {
  background-image: url("/assets/images/icons/Scroll.png");
}
.icon-Scroll-Auto {
  background-image: url("/assets/images/icons/Scroll-Auto.png");
}
.icon-Scroll-Loop {
  background-image: url("/assets/images/icons/Scroll-Loop.png");
}
.icon-Scroll-Scope {
  background-image: url("/assets/images/icons/Scroll-Scope.png");
}
.icon-Scroll-Sync {
  background-image: url("/assets/images/icons/Scroll-Sync.png");
}
.icon-Select {
  background-image: url("/assets/images/icons/Select.png");
}
.icon-Select-Select {
  background-image: url("/assets/images/icons/Select-Select.png");
}
.icon-Select-Unselect {
  background-image: url("/assets/images/icons/Select-Unselect.png");
}
.icon-Select-UnselectAll {
  background-image: url("/assets/images/icons/Select-UnselectAll.png");
}

.icon-Unselect {
  background-image: url("/assets/images/icons/SortAcquisition.png");
}
.icon-Selected {
  background-image: url("/assets/images/icons/SortImageNum.png");
}
.icon-SortAscending {
  background-image: url("/assets/images/icons/SortAscending.png");
}

.icon-SortDescending {
  background-image: url("/assets/images/icons/SortDescending.png");
}

.icon-SyncImage {
  background-image: url("/assets/images/icons/SyncImage.png");
}
.icon-Tool {
  background-image: url("/assets/images/icons/Tool.png");
}
.icon-ToolGlass {
  background-image: url("/assets/images/icons/ToolGlass.png");
}
.icon-UnsyncImage {
  background-image: url("/assets/images/icons/UnsyncImage.png");
}
.icon-ViewReport {
  background-image: url("/assets/images/icons/ViewReport.png");
}
.icon-ActualSize {
  background-image: url("/assets/images/icons/ActualSize.png");
}
.icon-ai-diagnosis {
  background-image: url("/assets/images/icons/ai-diagnosis.png");
}
.icon-Angle {
  background-image: url("/assets/images/icons/Angle.png");
}
.icon-Annotate {
  background-image: url("/assets/images/icons/Annotate.png");
}
.icon-Annotate-Arrow {
  background-image: url("/assets/images/icons/Annotate-Arrow.png");
}
.icon-AreaEclipse {
  background-image: url("/assets/images/icons/AreaEclipse.png");
}
.icon-AreaPolygon {
  background-image: url("/assets/images/icons/AreaPolygon.png");
}
.icon-AreaRectangle {
  background-image: url("/assets/images/icons/AreaRectangle.png");
}
.icon-Arrow {
  background-image: url("/assets/images/icons/Arrow.png");
}
.icon-MPR {
  background-image: url("/assets/images/icons/mpr.png");
}
.icon-Fusion {
  background-image: url("/assets/images/icons/fusion.png");
}
.icon-Unchecked {
  background-image: url("/assets/images/icons/Sort-Scope.png");
}
.icon-Checked {
  background-image: url("/assets/images/icons/checked.png");
}
.icon-Bidirectional {
  background-image: url("/assets/images/icons/Bidirectional.png");
}
.icon-AreaCircle {
  background-image: url("/assets/images/icons/AreaCircle.png");
}
.icon-PlanarFreehand {
  background-image: url("/assets/images/icons/PlanarFreehand.png");
}
