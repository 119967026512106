:root {
  --app-z-index-reset: 0;
  --app-z-index-base: 1;
  --app-z-index-overlay: 3;
  --app-z-index-highest: 9999;
  --app-w-user-info: 360px;
  --app-w-study-table: 640px;
  --app-h-meta-data: 150px;
  --app-h-page-footer: 40px;
}
