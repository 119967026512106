.w-10 {
  width: 10% !important;
}
.w-20 {
  width: 20% !important;
}
.w-30 {
  width: 30% !important;
}
.w-40 {
  width: 40% !important;
}
.w-50 {
  width: 50% !important;
}
.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}
.w-80 {
  width: 80% !important;
}
.w-90 {
  width: 90% !important;
}

.h-10 {
  height: 10% !important;
}
.h-20 {
  height: 20% !important;
}
.h-30 {
  height: 30% !important;
}
.h-40 {
  height: 40% !important;
}
.h-50 {
  height: 50% !important;
}
.h-60 {
  height: 60% !important;
}
.h-70 {
  height: 70% !important;
}
.h-80 {
  height: 80% !important;
}
.h-90 {
  height: 90% !important;
}
