/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

@import "theme/variables";

// Theme variables, must be included before the libraries to allow overriding defaults
@import "theme/theme-variables";

// 3rd party libraries
@import "bootstrap/scss/bootstrap";
@import "ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
// Theme customization
@import "theme/theme";

@import "assets/styles/icon";
@import "assets/styles/sizing";
